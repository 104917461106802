menu,
ol,
ul {
    list-style-type: disc;
    list-style: disc;
  /*! list-style:none; *//*! margin:0; *//*! padding:0 */
}

ul {
  padding-left: 1.5rem !important; /* Tailwind's default */
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
}

li {
  padding-left: 0.5rem !important;
  margin-bottom: 0.25rem !important;
}